<template>
  <div id="index" class="elModel" style="background: #f5f6fb; overflow-y: scroll">
    <div class="main-view mockup_bg">
      <el-row style="width: 100%; height: 100%">
        <el-col :span="14" style="height: 100%">
          <div class="left">
            <div class="title" style="position: absolute">
              供应商综合评选指标
            </div>
            <div class="myChartDaat">
              <el-image
                class="daatImg"
                :src="require('@/assets/img/supplychain/model.gif')"
              ></el-image>
            </div>
            <el-table
              :data="tableData"
              :row-class-name="tableRowClassName"
              :header-cell-style="{ background: '#EAFFF3', color: '#2B6645' }"
              style="width: 74%;margin-left:20px;top:-50px;"
              size="mini"
            >
              <el-table-column prop="name" label="评选项"> </el-table-column>
              <el-table-column prop="num" label="满分"> </el-table-column>
              <el-table-column prop="score" label="综合得分">
                <template slot-scope="{ row }">
                  <span
                    :class="row.score > 4.4 ? 'greenColor' : 'redColor'"
                    style="font-size: 14px; font-weight: bold"
                    >{{ row.score }}分</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="appraise" label="评选标准" width="320">
                <template slot-scope="{ row }">
                  <div class="appraise">
                    <div class="item" v-for="(v, i) in row.appraise" :key="i">
                      {{ i + 1 }}. {{ v }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="13" style="position: absolute; right: 0">
          <el-row>
            <el-col :span="24" style="padding-right: 20px;margin-top:20px;">
              <div class="myChartLine shadow" id="myChartLine">
                <Echart
                  :options="options2"
                  :className="'myChartLineBox'"
                  height="100%"
                  width="100%"
                ></Echart>
              </div>
            </el-col>
            <el-col :span="24" style="padding-right: 12px">
              <div class="top10_wrap shadow">
                <div class="top-title">
                  <span>优质供应商 Top 20</span>
                  <el-image
                    class="star"
                    :src="require('@/assets/img/supplychain/icon@2x.png')"
                  ></el-image>
                </div>
                <div class="content-box">
                  <vue-seamless-scroll
                    :data="list"
                    class="seamless-warp"
                    :class-option="classOption"
                  >
                    <div
                      class="list"
                      v-for="(item, index) in list"
                      :key="index"
                    >
                      <div class="flex">
                        <img
                          v-if="item.i == 0"
                          class="star"
                          :src="require('@/assets/img/supplychain/1@2x.png')"
                        />
                        <img
                          v-else-if="item.i == 1"
                          class="star"
                          :src="require('@/assets/img/supplychain/2@2x.png')"
                        />
                        <img
                          v-else-if="item.i == 2"
                          class="star"
                          :src="require('@/assets/img/supplychain/3@2x.png')"
                        />
                        <span v-else class="star star_bg">{{
                          item.i + 1
                        }}</span>
                        <div style="width: 100px">{{ item.supplier_name }}</div>
                      </div>
                      <div>
                        <div>商品质量</div>
                        <div class="score">{{ item.type_agv2 }}分</div>
                      </div>
                      <div>
                        <div>商品售价</div>
                        <div class="score">{{ item.type_agv1 }}分</div>
                      </div>
                      <div>
                        <div>配送效率</div>
                        <div class="score">{{ item.type_agv4 }}分</div>
                      </div>
                      <div>
                        <div>服务态度</div>
                        <div class="score">{{ item.type_agv5 }}分</div>
                      </div>
                      <div>
                        <div>供应量</div>
                        <div class="score">{{ item.type_agv3 }}分</div>
                      </div>
                      <div class="total_score">
                        <div class="greenColor">综合</div>
                        <div class="score total">{{ item.score }}</div>
                      </div>
                    </div>
                  </vue-seamless-scroll>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Echart from "@/components/echart/index.vue";
import * as $echarts from "echarts";
import VueSeamlessScroll from "vue-seamless-scroll";

export default {
  name: "SupplychainMockup",
  components: {
    Echart,
    VueSeamlessScroll,
  },
  data() {
    return {
      classOption: {
        step: 3, // 滚动速度
        singleHeight: 68, // 滑动高度
        waitTime: 2000, //停顿时间
      },
      timer: null,
      animate: false,
      cateArr: ["蔬菜", "肉类", "水产类", "水果类"],
      cateIndex: 0,
      tableData: [
        {
          name: "商品价格",
          num: "5.0 分",
          score: "4.6",
          appraise: [
            "大部分商品价格偏高",
            "有些商品价格偏高",
            "价格都与市场行情一致",
            "价格都略低市场行情",
            "价格比市场行情优惠很多",
          ],
        },
        {
          name: "配送效率",
          num: "5.0 分",
          score: "4.5",
          appraise: [
            "经常送货不及时",
            "有时送货不及时",
            "很少送货不及时",
            "偶尔送货不及时",
            "非常及时",
          ],
        },
        {
          name: "服务态度",
          num: "5.0 分",
          score: "4.1",
          appraise: [
            "缺少服务意识、态度不好",
            "服务态度一般",
            "服务态度良好",
            "讲诚信非常热情",
            "售后积极处理",
          ],
        },
        {
          name: "供应量",
          num: "5.0 分",
          score: "4.2",
          appraise: [
            "经常出现缺货",
            "有时出现缺货",
            "很少出现缺货",
            "偶尔出现缺货",
            "没有出现过缺货",
          ],
        },
        {
          name: "商品质量",
          num: "5.0 分",
          score: "4.8",
          appraise: [
            "经常出现质量问题",
            "有时出现质量问题",
            "很少出现质量问题",
            "偶尔出现质量问题",
            "没有出现质量问题",
          ],
        },
      ],
      list: [],

      options2: {
        backgroundColor: "#fff",
        title: {
          text: "商品价格对比",
          textStyle: {
            rich: {
              a: {
                fontSize: 16,
                fontWeight: 600,
              },
            },
          },
          top: "4%",
          left: "2%",
        },
        legend: {
          data: ["壹号净菜", "南昌深农批", "北京新发地","食品商务网"],
          textStyle: {
            align: "right",
          },
          top: "4%",
          right: "2%",
        },

        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(102, 102, 102, 1)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            // type: "shadow",
            label: {
              show: true,
              fontSize: 12,
              fontWeight: "bold",
              borderColor: "#fff",
              color: "#2CB167",
            },
          },

          formatter: function (params) {
            let res = "";
            return (res =
              params[0].name +
              "<br/>" +
              params[0].seriesName +
              " : ¥ " +
              params[0].value +
              "/kg<br/>" +
              params[1].seriesName +
              " : ¥ " +
              params[1].value +
              "/kg<br/>" +
              params[2].seriesName +
              " : ¥ " +
              params[2].value +
              "/kg<br/>" +
              params[3].seriesName +
              " : ¥ " +
              params[3].value +
              "/kg");
          },
        },

        grid: {
          top: "25%",
          left: "6%",
          right: "4%",
          bottom: "12%",
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            splitArea: {
              // show: true,
              color: "#f00",
              lineStyle: {
                color: "#f00",
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              margin: 10,
            },
            boundaryGap: false,
            // data: ['蔬菜','水果','肉类','水产','副食品'],
            data: [],
          },
        ],

        yAxis: [
          {
            name:'元/kg',
            nameTextStyle: {
              color: "#FF8300",
            },
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true, // 是否显示刻度线
              lineStyle: {
                color: "#e3e3e3",
              },
            },
            axisLine: {
              show: true, // x轴线
            },
            axisLabel: {
              show: true,
              margin: 10,
              formatter: "{value} ",
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "壹号净菜",
            type: "line",
            smooth: true, //是否平滑
            showAllSymbol: false,
            showSymbol: false, // 节点
            // symbol: 'circle', // 节点是否实心
            symbolSize: 8, // 节点圈大小
            lineStyle: {
              normal: {
                color: "#2CB167",
              },
            },
            itemStyle: {
              color: "#2CB167",
              borderWidth: 3,
              borderColor: "#f3f3f3",
            },
            data: [8.2, 8.8, 19.8, 18.1, 12.2],
            // data: [5.2, 6.9, 9, 10, 12],
          },
          {
            name: "南昌深农批",
            type: "line",
            smooth: true, //是否平滑
            showAllSymbol: true,
            showSymbol: false, // 节点
            // symbol: 'circle',
            symbolSize: 8,
            lineStyle: {
              normal: {
                color: "#FF9102",
              },
            },
            itemStyle: {
              color: "#FF9102",
              borderColor: "#fff",
              borderWidth: 3,
            },
            data: [9.5, 8.5, 20.5, 16.9, 9.5],
            // data: [5.5, 6, 8.5, 11.7, 11.8],
          },
          {
            name: "北京新发地",
            type: "line",
            smooth: true, //是否平滑
            showAllSymbol: true,
            showSymbol: false, // 节点
            // symbol: 'circle',
            symbolSize: 8,
            lineStyle: {
              normal: {
                color: "#4B9AF9",
              },
            },
            itemStyle: {
              color: "#4B9AF9",
              borderColor: "#fff",
              borderWidth: 3,
            },
            data: [9.3, 21, 49.8, 43.4, 9.3],
            // data: [5.8, 7.1, 8.1, 11.1, 13.9],
          },
          {
            name: "食品商务网",
            type: "line",
            smooth: true, //是否平滑
            showAllSymbol: true,
            showSymbol: false, // 节点
            // symbol: 'circle',
            symbolSize: 8,
            lineStyle: {
              normal: {
                color: "#FF4949",
              },
            },
            itemStyle: {
              color: "#FF4949",
              borderColor: "#fff",
              borderWidth: 3,
            },
            data: [10.2, 19.6, 45.6, 38.8, 10.2],
            // data: [6.3, 6.8, 9.1, 11.8, 12.9],
          },
        ],
      },
    };
  },
  created() {
    this.getRank();
    this.getLine();
  },
  methods: {
    // 获取前10供应商
    getRank() {
      this.$http.get("/admin/score/rank", { params: {} }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list.map((item, index) => {
            return {
              ...item,
              i: index,
            };
          });
        }
      });
    },
    // 商品价格对比折线图
    getLine() {
      this.$http.get("/admin/score/line", { params: {} }).then((res) => {
        if (res.code == 1) {
          this.options2.xAxis[0].data = res.data.list.map((a) => {
            return a.name;
          });
          const arr1 = [];
          const arr2 = [];
          const arr3 = [];
          const arr4 = [];
          res.data.list.map((b) => {
            b.price.map((item) => {
              switch (item.source) {
                case 1:
                  arr1.push(item.price);
                  break;
                case 2:
                  arr2.push(item.price);
                  break;
                case 3:
                  arr3.push(item.price);
                  break;
                case 4:
                  arr4.push(item.price);
                  break;
                default:
                  break;
              }
            });
          });
          console.log(arr1,"arr1")
          console.log(arr2,"arr2")
          console.log(arr3,"arr3")
          console.log(arr4,"arr4")
          this.options2.series[0].data = arr3; // 3:壹号净菜
          this.options2.series[1].data = arr4; // 4:深农批
          this.options2.series[2].data = arr2; // 2:北京新发地
          this.options2.series[3].data = arr1; // 1:食品商务网
        }
      });
    },

    // 表格综合得分小于3.5分给红色背景色提示
    tableRowClassName({ row, rowIndex }) {
      console.log(row, "row");
      console.log(rowIndex, "rowIndex");
      // return
      if (row.score < 4.5) {
        return "warning-row";
      }
      return "";
    },
  },
};
</script>

<style scoped>
/* el-table 取消鼠标移入时变色 */
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent;
}
::v-deep .el-table .warning-row {
  background: #fff5f2;
}
.main-view {
  padding: 0 !important;
}
.flex {
  display: flex;
  align-items: center;
}
.elModel::-webkit-scrollbar {
  width: 0;
}
.left {
  /* padding-bottom: 20px; */
  display: flex;
  flex-direction: column;
}
.mockup_bg {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.mockup_bg ::v-deep .el-table {
  border-radius: 10px;
}
.title {
  padding: 1% 2%;
  font-size: 18px;
  font-weight: bold;
}
.appraise {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  color: #666;
}
.appraise .item {
  width: 150px;
  line-height: 20px;
}

/* top10 */
.top10_wrap {
  margin-top: 20px;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  /* margin-bottom: 20px; */
}
.top10_wrap .top-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.top10_wrap .star {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  color: #333;
}
.top10_wrap .star_bg {
  background-image: url("~@/assets/img/supplychain/4@2x.png");
  background-size: 28px;
  background-repeat: no-repeat;
}
/* 滚动条样式 */
.content-box::-webkit-scrollbar {
  width: 0;
}
.top10_wrap .list:nth-child(-n + 3) {
  background-color: #f1fff7;
}
.top10_wrap .list {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-radius: 5px;
  border: solid 1px #eee;
  margin-top: 6px;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: top 0.5s;
}
/* .anim {
  transition: all 0.5s;
  margin-top: -60px;
} */
.top10_wrap .score {
  margin-top: 5px;
}
.top10_wrap .total {
  font-size: 14px;
  font-weight: bold;
  color: #2cb167;
}

.myChartDaat {
  width: 100%;
  /* height: 713px; */
  height: 50%;
  background-image: url("~@/assets/img/supplychain/53.png");
  background-size: 92%;
  background-repeat: no-repeat;
  background-position: -8% 50%;
  flex: 1;
}
.daatImg {
  margin-left: 2%;
  /* margin-top: 3%; */
  width: 74%;
}
.myChartLine {
  width: 100%;
  height: 268px;
  background: #fff;
  border-radius: 10px;
  padding: 4px;
}
.caterWrap {
  position: absolute;
  top: 35px;
  left: 20px;
  z-index: 99;
}
.cate-item {
  border-radius: 20px;
  padding: 2px 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
}
.active {
  background: #2cb167;
  color: #fff;
}

.seamless-warp {
  /* max-height: 480px; */
  overflow: hidden;
}
@media screen and (max-width: 1920px) {
  .seamless-warp {
    max-height: 740px;
    overflow: hidden;
  }
  .content-box {
    width: 100%;
    max-height: 740px;
    background-color: #fff;
    overflow: hidden;
  }
}
@media screen and (max-width: 1440px) {
  .seamless-warp {
    max-height: 530px;
    overflow: hidden;
  }
  .content-box {
    width: 100%;
    max-height: 530px;
    background-color: #fff;
    overflow: hidden;
  }
}

</style>